/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */

@import "~@ionic/angular/css/core.css";
@import "swiper/swiper-bundle.min.css";
@import "swiper/swiper.min.css";
@import "~@ionic/angular/css/ionic-swiper";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */

@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@import "~@swimlane/ngx-datatable/index.css";
@import "~@swimlane/ngx-datatable/assets/icons.css";

@font-face {
  font-family: "open-sans";
  src: url("../src/assets/font/Open_Sans/static/OpenSans/OpenSans-Light.ttf");
}

@font-face {
  font-family: "open-sans-semi-bold";
  src: url("../src/assets/font/Open_Sans/static/OpenSans/OpenSans-SemiBold.ttf");
}

@font-face {
  font-family: "open-sans-bold";
  src: url("../src/assets/font/Open_Sans/static/OpenSans/OpenSans-Bold.ttf");
}

.split-pane-visible > .split-pane-side {
  min-width: 220px !important;
  max-width: 220px !important;
}

ion-accordion-group ion-accordion .ion-accordion-toggle-icon {
  font-size: 15px;
}
ion-toast.toast {
  ::part(button) {
    --button-color: green !important;
  }
}

.loader {
  --spinner-color: #e81f29;
  font-family: open-sans;
  font-size: 16px;
  --backdrop-opacity: 0.8;
  .spinner {
    width: 100px;
  }
  .loading-wrapper {
    background: transparent !important;
    box-shadow: none !important;
  }
}

ion-content,
ion-text,
ion-label,
ion-card,
ion-item,
ion-input,
ion-note,
ion-chip,
ion-select {
  font-family: open-sans !important;
}
h1,
h2,
h3,
h4,
h5,
h6,
ion-title,
ion-card-title {
  font-family: open-sans !important;
  font-weight: bold;
}
.ngx-datatable {
  width: fit-content;
  overflow-y: auto;
  margin: auto;
}
.table {
  padding: 5px;
  max-height: 75vh;
  overflow-x: scroll;
  overflow-y: scroll;
  margin: auto;
}
.swiper-slide,
.swiper-pagination {
  --bullet-background: #e81f29;
  --bullet-background-active: var(--ion-color-warning);
}

// Table Style
.table .ngx-datatable.dark {
  box-shadow: none;
  background: hsl(0, 0%, 13%);
  border: none;
  border-radius: 5px;
  color: #fff;
  font-size: 13px;

  .datatable-header {
    background: hsl(0, 0%, 19%);
    color: #c3c2c2;

    .datatable-header-cell {
      text-align: center;
      padding: 0.5rem 1.2rem;
      font-weight: bold;

      .datatable-header-cell-label {
        line-height: 24px;
      }
    }
  }
  .row-expanded {
    background-color: #303030;
    height: auto;
  }
  .datatable-row-detail {
    background: #303030;
  }
  .datatable-body {
    background: hsl(0, 0%, 13%);

    .datatable-body-row {
      border: none;

      .datatable-body-cell {
        text-align: center;
        padding: 0.5rem 1.2rem;
        vertical-align: top;
        font-size: 12px;
      }

      &:hover {
        background: #303030;
        transition-property: background;
        transition-duration: 0.3s;
        transition-timing-function: linear;
      }

      &:focus {
        background-color: #363636;
      }

      &.active {
        background-color: #404040;
        color: #fff;
      }
    }
  }

  .datatable-footer {
    background: hsl(0, 0%, 15%);
    color: #bdbdbd;
    margin-top: -1px;

    .page-count {
      line-height: 50px;
      height: 40px;
      padding: 0 1.2rem;
    }

    .datatable-pager {
      margin: 0 10px;
      vertical-align: top;

      ul {
        li {
          margin: 10px 0px;

          &:not(.disabled) {
            &.active a,
            &:hover a {
              background-color: #303030;
              font-weight: bold;
            }
          }
        }
      }

      a {
        height: 22px;
        min-width: 24px;
        line-height: 22px;
        padding: 0;
        border-radius: 3px;
        margin: 0 3px;
        text-align: center;
        vertical-align: top;
        text-decoration: none;
        vertical-align: bottom;
        color: #72809b;
      }

      .datatable-icon-left,
      .datatable-icon-skip,
      .datatable-icon-right,
      .datatable-icon-prev {
        font-size: 18px;
        line-height: 27px;
        padding: 0 3px;
      }
    }
  }

  .datatable-summary-row {
    .datatable-body-row {
      background-color: #14171f;

      &:hover {
        background-color: #303030;
      }

      .datatable-body-cell {
        font-weight: bold;
      }
    }
  }
}
// scrollbar
::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0);
  border-radius: 10px;
}

::-webkit-scrollbar {
  width: 10px;
  height: 12px;
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-clip: padding-box;
  border-radius: 5px;
  background-color: #404040;
}

::-webkit-scrollbar-corner {
  background-color: transparent;
}

.alert-controller .alert-btn-secondary {
  color: var(--ion-color-warning);
  text-transform: none;
}

.alert-controller .alert-btn-primary {
  color: var(--ion-color-warning);
  text-transform: none;
}

ion-select-popover ion-item {
  font-size: 12px;
  --min-height: 30px;
  overflow: hidden;
}

ion-modal {
  --backdrop-opacity: 0.5;
}

ion-breadcrumb {
  --color-active: var(--ion-color-warning-shade);
  --color-hover: var(--ion-color-warning);
  font-size: 12px;
}

.focus-breadcrumb {
  cursor: pointer;
}

.focus-breadcrumb:hover {
  font-weight: bold;
  text-decoration: underline;
}

.menu {
  --max-width: 220px;
}
